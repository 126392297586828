<template>
  <div key="price_set">
    <template v-if="sku">
      <div class="head-container">
        <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
        <el-button class="filter-item" type="danger" icon="el-icon-delete" :disabled="!selection || !selection.length" :loading="delLoading" @click="batchRemove">批量删除</el-button>
      </div>

      <el-table ref="list" v-loading="loading" :data="data" row-key="id" height="200" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="35" reserve-selection />
        <el-table-column prop="provinceName" label="省份" min-width="100" />
        <el-table-column prop="cityName" label="城市" min-width="100" />
        <el-table-column prop="districtName" label="区域" min-width="100" />
        <el-table-column prop="price" label="零售价" min-width="80" :formatter="$price" />
        <!-- <el-table-column label="状态" width="80">
          <template slot-scope="scope">
            <dot :type="scope.row.enable ? 'success' : 'gray'" same>{{scope.row.enable ? "已启用" : "已禁用"}}</dot>
          </template>
        </el-table-column>-->
        <el-table-column width="100" fixed="right">
          <div slot-scope="scope" class="row-commands">
            <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
            <el-popover :ref="scope.row.id" placement="top" width="180">
              <p>确定删除本条数据吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
              </div>
              <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
            </el-popover>
          </div>
        </el-table-column>
      </el-table>

      <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    </template>
    <span class="fc-g" v-else>请先在左侧选择要进行零售价管理的商品。</span>

    <eForm ref="form" @complete="toQuery" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { delByArea } from "@/api/retailPrice";
import eForm from "./form";

export default {
  components: { eForm },
  mixins: [initData],
  props: {
    sku: Object
  },
  data() {
    return {
      selection: null
    };
  },
  watch: {
    sku: "handleSkuChange"
  },
  methods: {
    checkPermission,
    beforeInit() {
      if (!this.sku) return false;
      this.url = "api/shop/areaPrice";
      this.params = {
        goodsSkuId: this.sku.id,
        cityCodeNot: "all"
      };
      return true;
    },
    handleSelectionChange(sels) {
      this.selection = sels;
    },
    handleSkuChange() {
      this.$refs.list && this.$refs.list.clearSelection();
      this.selection = null;
      this.toQuery();
    },
    subDelete(id) {
      this.delLoading = true;
      delByArea([id])
        .then(res => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500
          });
        })
        .catch(err => {
          this.delLoading = false;
          this.$refs[id].doClose();
        });
    },
    batchRemove() {
      if (this.selection && this.selection.length) {
        this.$confirm(
          `确定要删除当前商品勾选的${this.selection.length}条指定区域零售价吗？`,
          "操作确认",
          {
            type: "warning"
          }
        ).then(res => {
          this.delLoading = true;
          let ids = this.selection.map(o => {
            return o.id;
          });
          delByArea(ids)
            .then(res => {
              this.$notify({
                title: "批量删除成功",
                type: "success",
                duration: 2500
              });
              this.handleSkuChange();
            })
            .finally(err => {
              this.delLoading = false;
            });
        });
      }
    },
    add() {
      this.$refs.form &&
        this.$refs.form.resetForm(null, {
          goodsSkuId: this.sku.id
        });
    },
    edit(data) {
      let fd = JSON.parse(JSON.stringify(data));
      if (fd.cityCode === "all") {
        fd.cityCode = null;
        fd.cityName = "";
      }
      if (fd.districtCode === "all") {
        fd.districtCode = null;
        fd.districtName = "";
      }
      this.$refs.form && this.$refs.form.resetForm(fd);
    }
  },
  mounted() {
    this.handleSkuChange();
  }
};
</script>